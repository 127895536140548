@tailwind base;
@tailwind components;
@tailwind utilities;

.ease {
  transition: all .4s ease;
  -webkit-transition: all .4s ease;
}

.easeFast {
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
}

.noDrag {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body::-webkit-scrollbar {
  width: 11px;
}

@font-face {
  font-family: "pixel";
  src: url("./assets/fonts/Pixellari.ttf");
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.loader {
  border-top-color: #1068FF;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.blackStroke {
  -webkit-text-stroke: 0.5px black;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}