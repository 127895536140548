html {
  background-color: #2E3192;
}

@keyframes ticker-tape {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.transition-opacity.duration-2500.ease-linear.opacity-100 {
  animation: fadeOut 2.5s linear forwards;
}

.ticker-tape-container {
  display: flex;
  animation-iteration-count: infinite;
  animation-timing-function: steps(1, end);
}